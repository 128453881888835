.popup {
  position: fixed;
  top: 5%;
  left: 36vw;
  width: 60vh;
  height: 70vh;
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: .4s ease;
}
.popup-inner {
  position: relative;
  padding: 20px;
  width: 60vh;
  max-width: 640px;
  background-color: white;
}
.popup-inner .close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
}

h4{
  overflow-wrap: break-word;
}
.specialButton{
 margin-right: 5px;
 width:25%;
 height:50px;
 background-color:#FF3030;
 border-radius:3px;
  border:1px solid black;

  font-size:medium;
}
.specialButton:hover{
  background-color: #FF0000;

}

.specialButton2{
margin-left:5px;
width:25%;
height:50px;
border-radius:3px;
border:1px solid black;
cursor: pointer;

}

.specialButton2:hover{
  background-color: lightgray;
}

.close-button{
  width:25%;
  height:50px;
  border-radius:3px;
  border:1px solid black;
  background-color: crimson;
  margin:"25px"
}

.close-button:hover{
  background-color: purple;
  color:black;
}

.textInput{
  background-color: #F5F5F5 !important;
}

.textInput:hover{
  background-color: #8DA5E2 !important;
}


.cancelIcon{
  height: fit-content;
  width: fit-content;
  background-color:rgb(255, 118, 118);
  border-radius: 50%;
}

.cancelIconButton{
  background-color: transparent;
   border: none;
   color:rgb(255, 118, 118);

   width: auto;
    height: auto;
}

.cancelIcon:hover{
  background-color: rgb(125, 33, 33) !important;
}


.specialButton3{
  background-color:#709176;
  padding:8px;
  border-radius: 3px;
  border:1px solid black;
  padding:6px;

}

.specialButton3:hover{
  background-color: #506854;
}

.downloadIcon{
  height: 50px;
  width: 50px;
  margin-top: -50px;
  margin-right: -25px;
  stroke: black;
  stroke-width:0.6;
  padding-bottom: 15px;
  
}

.downloadIcon:hover{
  color: white;
}
.butt-pop1{
  border: 2px solid #0aaff0;
  margin-bottom: 5px;
  color: #0aaff0;
  transition: ease-in 0.3s;
  background-color: white;
  font-size: 15px;

  border-radius: 5px;
  width: fit-content;
  font-family: 'Rubik';
  cursor: pointer;

}
.butt-pop1:hover{
  color: white !important;
 border: 2px solid whitesmoke;
 box-shadow: inset 200px 0 0 0 #0aaff0;

}
.butt-pop2{
  border: 2px solid rgb(232, 92, 92);
  margin-bottom: 5px;
  color:  rgb(232, 92, 92);
  transition: ease-in 0.3s;
  background-color: white;
  font-size: 15px;
  border-radius: 5px;
  font-family: 'Rubik';
  width: fit-content;
  cursor: pointer;

}
.butt-pop2:hover{
  color: white !important;
 border: 2px solid whitesmoke;
 box-shadow: inset 200px 0 0 0  rgb(232, 92, 92);

}
.bg-pop{
  color: #4381C1;
  padding: 5px;
  width: fit-content;


}

.check-input{
  width: 20vw !important;
  margin-top: 10px;
  background-color: white !important;
  margin-left: 10px;
}
.check-form{

  text-align: center;
}
.pop-opac{
  width: 100vw;
  height: 100% !important;
  background-color: rgb(0, 0, 0, 0.5) !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 1050;

}
.search-list{
  color: white !important;
  border-bottom: none !important;
  margin-top: 5px;
}

.editIcon{
  width:auto;
  border:none;
  background-color:transparent;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.editIcon:hover{
  border:1px solid black;
}
.addEle:hover{
  background-color:#ef476f !important;
}
.addEle:hover h4{
  color:white !important;

}
.addEle2:hover{
  background-color:#25a18e !important;
}
.addEle2:hover h4{
  color:white !important;

}
.addEle{
  transition: 0.2s ease-in-out;
  background-color:transparent;
}
.addEle label{
  transition: 0.2s ease-in-out;

}
.addEle2{
  transition: 0.2s ease-in-out;
  background-color:transparent;
}
.addEle2 label{
  transition: 0.2s ease-in-out;

}
.modif{
  transition: 0.3s ease-in-out;
}
