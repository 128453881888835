:root{
    --color-primary: #6C9BCF;
    --color-danger: #FF0060;
    --color-success: #1B9C85;
    --color-warning: #F7D060;
    --color-white: #fff;
    --color-info-dark: #7d8da1;
    --color-dark: #363949;
    --color-light: rgba(132, 139, 200, 0.18);
    --color-dark-variant: #677483;
    --color-background: #f6f6f9;
    --color-dark-blue: #14213d;
    --card-border-radius: 2rem;
    --border-radius-1: 0.4rem;
    --border-radius-2: 1.2rem;
    --light-blue:#0496ff;
    --card-padding: 1.8rem;
    --padding-1: 1.2rem;
    --blue-botton:#192bc2;

    --box-shadow: 0 2rem 3rem var(--color-light);
  }

  /*BUTTONS*/

.dash-section-on{
    color:white;
    background-color: #192bc2;
    border-radius:50px;
    width:8vw;
    font-family: 'Poppins';
    font-weight: bold;
    border:none;
    cursor: pointer !important;
    box-shadow: rgba(0, 0, 0, 0.5) 1.95px 1.95px 2.6px;

}
.dash-section-off{
    color:#192bc2;
    background-color: white;
    border-radius:50px;
    width:8vw;
    font-family: 'Poppins';
    font-weight: normal;
    border:1px solid #192bc2;
    cursor: pointer !important;
    
}

.text-buttons{
    border-bottom:1px solid #192bc2 !important;
    color:#192bc2;
    cursor:pointer;
    font-size:0.8rem;
    background-color:transparent;
    font-family: 'Poppins';
    border:none;
    width:fit-content;
    border-radius: 0;
}
.text-buttons2{
    border-bottom:1px solid #192bc2 ;
    color:#192bc2;
    cursor:pointer;
    font-size:0.8rem;
    background-color:transparent;
    font-family: 'Poppins';
    border:none;
    width:fit-content;
}
.back-button{
    color:var(--color-dark-blue);
    width:2rem !important;
    cursor: pointer;
}

.main-button{
    background-color: var(--color-dark-blue);
    box-shadow: var(--box-shadow);
    color:white;
    font-family: 'Poppins';
    font-size:0.8rem;
    border:none;
    font-weight: normal !important;
    border-radius: 5px;
    height:fit-content;
    padding: 5px;
    cursor: pointer;
}
.transparent-button{
    background-color: transparent;
    border: none;
    width:fit-content !important;
    cursor: pointer;
    padding:0;
    
}

/*INPUT*/

.input-style{
    border-radius: 5px;
    border: 1px solid var(--color-dark-blue) !important;
    width:12rem !important;
    font-family: 'Poppins';
    font-weight: normal;
    background-color: var(--color-background);
    box-shadow: var(--box-shadow) !important;
}
.input-style2{
    border-radius: 5px;
    border: 1px solid var(--color-dark-blue) !important;
    width:12rem ;
    font-family: 'Poppins';
    font-weight: bold;
    background-color: var(--color-background) !important;

}

/*TESTI*/

.bold-cool{
    font-family: 'Poppins';
    font-weight: 700;
    color:(var(--color-dark-blue));
    font-size: 1rem
}
.normal-cool{
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 1rem
}

.normal-text{
    font-family: 'Poppins';
    font-size:1.2rem;
    margin:0;
    font-weight: 400;

}
.normal-text2{
    font-family: 'Poppins';
    font-size:1rem;
    margin:0;
    font-weight: 400;

}

.sub-text{
    font-family: 'Poppins';
    font-size:1rem;
    margin:0;
    color: var(--color-dark-variant);
    font-weight: 400;
}
.sub-text-b{
    font-family: 'Poppins';
    font-size:1rem;
    margin:0;
    color: var(--color-dark-variant);
    font-weight: bold;
}

.header-normal{
    font-family: 'Poppins';
    color:black;
    font-size:1.1rem;
    margin:0;
}

.title-section{
    font-family: 'Poppins';
    color:var(--color-dark-blue);
    font-size:1.6rem;
    margin:0;
}

.title-medium{
    font-family: 'Poppins';
    color:var(--color-dark-blue);
    font-size:1.2rem;
    margin:0;
}


.page-title{
    font-size: 2.5rem;
    color: var(--color-dark-blue);
    font-weight: bold;
    font-family: "Poppins";
    margin-bottom: 0;
    width: fit-content;
}

.medium-text{
    font-family: 'Poppins';
    font-size:0.8rem;
    font-weight: normal;
    width:fit-content !important;
    height:fit-content !important;
    color:black;
    margin-top:0;
    margin-bottom:0 !important;
    margin-left: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*RIGHE */

.flex-row{
    display:flex;
    align-items: center !important;
    border-bottom:1px solid var(--color-dark-variant);
    width:80rem !important;
}

.section-style-on{
    width:100%;
    border:none;
    background-color: var(--blue-botton);
    font-family: 'Poppins';
    border-radius: 0;
    font-size: 0.9rem;
    cursor: pointer;
}
.section-style-off{
    width:100%;
    border:none;
    font-family: 'Poppins';
    border-radius: 0;
    font-size: 0.9rem;
    color:var(--color-dark-blue);
    background-color: transparent;
    cursor: pointer;

}

.table-row{
    margin-top:10px;
    border-radius:10px;
    width:82vw;
    background-color:var(--color-background);
    height:6rem;
    display:flex;
    align-items:center;
    box-shadow: var(--box-shadow);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

/*SHADOW BOX */
.box-shadow{
    box-shadow: var(--box-shadow) !important;
    transition: .4s ease;

}
.box-shadow:hover{
    box-shadow: none !important;
}

.tools{
    background-color: rgba(0, 0, 0, 0.6); /* Colore semi-trasparente per l'effetto glass */
    backdrop-filter: blur(10px); /* Effetto di sfocatura (da aggiustare in base al risultato desiderato) */
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 50px; /* Bordo tondo */
    position:absolute;
    padding:10px;

}


@media screen and (max-width: 1441px){
    .title-section{
        font-family: 'Poppins';
        color:var(--color-dark-blue);
        font-size:1rem;
        margin:0;
    }
    .page-title{
        font-size: 1.5rem;
        color: var(--color-dark-blue);
        font-weight: bold;
        font-family: "Poppins";
        margin-bottom: 0;
        width: fit-content;
    }
    .sub-text{
        font-family: 'Poppins';
        font-size:0.7rem;
        margin:0;
        color: var(--color-dark-variant);
        font-weight: 400;
    }
    .sub-text-b{
        font-family: 'Poppins';
        font-size:0.7rem;
        margin:0;
        color: var(--color-dark-variant);
        font-weight: bold;
    }
    
}





.page-subtitle{
    font-size: 1.2rem;
    color: #5C6672;
    font-weight: normal !important;
    font-family: "Rubik";
    margin-bottom: 0;
    width: fit-content;
}
.blue-button{
    box-shadow: rgba(0, 0, 0, 0.35) 1.95px 1.95px 1.6px;
    background-color: #192bc2;
    color: white;
    font-weight: 400;
    font-family: 'Rubik';
    font-size: 0.85rem;
    border: none;
    border-radius: 5px !important;
    letter-spacing: normal !important;
    width: 10rem;
    cursor: pointer;
    height:2.8rem;
    justify-content:center !important;
    align-items: center !important;
}
.blue-button-thick{
    box-shadow: rgba(0, 0, 0, 0.35) 1.95px 1.95px 1.6px;
    background-color: #192bc2;
    color: white;
    font-weight: 400;
    font-family: 'Rubik';
    font-size: 0.85rem;
    border: none;
    border-radius: 5px !important;
    letter-spacing: normal !important;
    width: 10rem;
    cursor: pointer;
    height:1.8rem;
    justify-content:center !important;
    align-items: center !important;
}
.blue-button2{
    box-shadow: rgba(0, 0, 0, 0.35) 1.95px 1.95px 1.6px;
    margin-right: 20;
    background-color: white;
    color: #192bc2;
    font-weight: 400;
    font-family: 'Rubik';
    border: 1px solid #192bc2 !important;
    font-size: 0.85rem;
    border: none;
    border-radius: 5px  !important;
    letter-spacing: normal !important;
    width: 8vw;
    cursor: pointer;
    height:4.5vh;
    justify-content:center !important;
    align-items: center !important;
}

.top-section{
    width: 92vw ;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    position: fixed;
    left: 0;
    padding-left: 8vw;
    top:0;
    background-color: white;
    height:5rem;
    z-index: 1000;
    padding-bottom: 1rem;
}
.body-text{
    font-size: 0.8rem !important;
    font-family: 'Rubik' !important;
    font-weight: 400 !important;
    margin-top: 0;
    margin-bottom:0;
    width:fit-content;
    height:fit-content;
}
.body-text2{
    font-size: 0.7rem !important;
    font-family: 'Rubik' !important;
    font-weight: 400 !important;
    margin-top: 0;
    margin-bottom:0;
    width:fit-content;
    height:fit-content;
}
.body-text-b{
    font-size: 1rem !important;
    font-family: 'Rubik' !important;
    font-weight: 600 !important;
    margin-top: 0;
    margin-bottom:0;
    width:fit-content;
    height:fit-content;
}
.input-filter{
    width:10rem;
    background-color: white;
    border: 1px solid #5C6672;
    border-radius: 5px;
    font-size: 0.8rem;
    height: 2rem;
    color:black !important;
    font-family: 'Rubik' !important;

}
.header-table{
    font-size: 0.9rem !important;
    font-family: 'Poppins' !important;
    font-weight: 400 !important;
    display: inline-block !important;
    width:fit-content !important;
    height:fit-content !important;
    color:white;
    margin-top:0.85rem;
    margin-bottom:0 !important;
    margin-left: 0.5rem;
}
.body-table{
    font-size: 0.9rem !important;
    font-family: 'Rubik' !important;
    font-weight: 600 !important;
    width:fit-content !important;
    height:fit-content !important;
    color:#0496ff;
    margin-top:0;
    margin-bottom:0 !important;
    margin-left: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.body-table2{
    font-size: 0.8rem !important;
    font-family: 'Rubik' !important;
    font-weight: 400 !important;
    width:fit-content !important;
    height:fit-content !important;
    color:black;
    margin-top:0;
    margin-bottom:0 !important;
    margin-left: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.body-sub-table{
    font-size: 0.7rem !important;
    font-family: 'Rubik' !important;
    font-weight: 400 !important;
    width:fit-content !important;
    height:fit-content !important;
    color:black;
    margin-top:0;
    margin-bottom:0 !important;
    margin-left: 0.5rem;
}

.header-sect-pop{
    margin-top:0;
    margin-bottom:0 !important; 
    font-family: 'Rubik' !important;
    font-size: 1.5rem ;
    max-width: 20vw !important;

}
.body-sect-pop{
    margin-top:0;
    margin-bottom:0 !important; 
    font-family: 'Rubik' !important;
    font-size: 1.2rem !important;
    font-weight: normal;
}

.select-standard{
    width:20rem;
    background-color: white;
    font-family: 'Rubik';
    
}

.myele-button{
    opacity: 0;
}
.myele-elem:hover .myele-button{
    opacity: 100;
}

.btnD{
    width:fit-content;
    background-color: transparent;
    border:none;
}

/* Barra di scorrimento */
::-webkit-scrollbar {
    width: 12px;  /* Larghezza della barra di scorrimento */
    background-color: #f6f6f9;
    z-index: 1000000;
  }
  
  /* Cursore della barra di scorrimento */
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 6px;
    border: 3px solid transparent;  /* Per creare spazio tra cursore e tracciato */
    background-clip: content-box;   /* Assicura che il bordo non copra il cursore */
  }
  
  /* Tracciato della barra di scorrimento */
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 6px;

}
  
  /* Al passaggio del mouse sul cursore */
  ::-webkit-scrollbar-thumb:hover {
    background-color: grey;
  }
