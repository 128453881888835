:root{
    --color-primary: #6C9BCF;
    --color-danger: #FF0060;
    --color-success: #1B9C85;
    --color-warning: #F7D060;
    --color-white: #fff;
    --color-info-dark: #7d8da1;
    --color-dark: #363949;
    --color-light: rgba(132, 139, 200, 0.18);
    --color-dark-variant: #677483;
    --color-background: #f6f6f9;
    --color-dark-blue: #14213d;
    --card-border-radius: 2rem;
    --border-radius-1: 0.4rem;
    --border-radius-2: 1.2rem;
    --light-blue:#0496ff;
    --card-padding: 1.8rem;
    --padding-1: 1.2rem;
    --blue-botton:#192bc2;
  
    --box-shadow: 0 2rem 3rem var(--color-light);
  }

.calendar-box{
  width:72rem;
  background-color:'transparent';
  height:28rem;
  border-radius:10;
  z-index:100;
}
.calendar-box-inner{
  width:72rem;
  height:2rem;
  border-radius:10;
  display:flex;
  align-items:center;
}

.butt-calendar-top:hover{
    background-color: #192bc2 !important;
    color: white !important;
}
@media screen and (max-width: 1440px){
  .calendar-box{
    width:59rem;
    background-color:'transparent';
    height:26rem;
    border-radius:10;
    z-index:100;
  }
  .calendar-box-inner{
    width:59rem;
    height:1rem;
    border-radius:10;
    display:flex;
    align-items:center;
  }
  
}