.pre-link:hover{
    color:#0496ff !important;
}
.pre-container{
    display: grid;
    grid-template-columns: 1fr 1fr 0.2fr 1fr 1fr;
    grid-template-rows: 2fr 0.3fr;
    grid-gap: 0.5rem;

    
}
.pre-container2{
    display: grid !important;
    grid-template-columns: 5fr 0.9fr 0.5fr 1.4fr 0.3fr;
    grid-template-rows: 2fr 0.3fr;
    grid-gap: 5rem; 
    margin-top:12vh;
}

.pre-content{
    padding-left:10 !important;
}
.pre-content2{
    visibility: visible !important;
}
.pre-img{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width:85%;
    margin-top:10 !important;
}
.pre-header{
    color:black;
    font-family: 'Poppins';
    margin-top:0;
    margin-bottom:0;
    font-size: 30px;
}
.pre-text{
    color:#5C6672;
    font-family: 'Poppins';
    margin-top:0;
    margin-bottom:0;
    font-size: 15px;
    font-weight: 400;
}
.pre-text-big{
    font-size: 3rem
}
.pre-all-cont{
    height:95vh;
    overflow-y: scroll;
}
.logo-div{
    visibility: hidden;
    height:0;
    width: 0;
}
@media only screen and (max-width:550px){
    .pre-container{
        grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 0.2fr;
    grid-gap: 0rem;
    }
    .logo-div{
        visibility: visible;
        height:40px;
    width: 40px;
    }
    .pre-all-cont{
        height:fit-content;
    }
    .pre-text-big{
        font-size: 4rem;
    }
    .pre-content{
        padding:5 !important;
    }
    .pre-content2{
        visibility: hidden !important;
    }
    .pre-container2{
        grid-template-columns:  1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 0rem; 
        height: 30vh;
        margin-top:0 !important;
    }
}