.inputReg{
  margin-top: 0.3vh;
  font-weight: normal;
  width: 15vw !important;
  font-family: "Montserrat";
  border-radius: 10px !important;
  background-color: whitesmoke !important;
  border: 1px solid #5C6672;
  font-size: 0.8vw;
  color: black;
  padding-bottom: 3px;
  text-align:center;
}

.headerInput{
  font-size: 1vw;
  color: #5C6672;
  font-weight: 400;
  font-family: "Montserrat";
  letter-spacing: -0.05vw;
  margin-top: 2vh;
  margin-bottom: 1vh;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
}

.backButton{
  background-color:#ef476f;
  color:white;
  font-family:'Montserrat'; 
  font-size:0.9vw;
  font-weight:bold;
  border:1px solid #e65577;
  border-radius:5; 
  width:15vw; 
  cursor:pointer;
  margin-left: auto;
  margin-right: auto;
}
.nextButton{
  background-color:#0496ff;
   color:white;
   font-family:'Montserrat'; 
   font-size:0.9vw;
   font-weight:bold;
   border:1px solid #118ab2;
   border-radius:5; 
   width:15vw; 
   cursor:pointer;
   margin-left: auto;
   margin-right: auto;
}

.registerpage {
  height: 100vh;
  width: 100vw;
  background-color: white;
}

.titleReg {
  font-size: 1.7vw;
  color: "black";
  font-weight: 600;
  font-family: "Montserrat";
  letter-spacing: -0.1vw;
  margin-bottom: 0;
  margin-top: 0;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
}
.subtitleReg{
  font-size: 1.2vw;
  color: #5C6672;
  font-weight: 400;
  font-family: "Montserrat";
 letter-spacing: -0.1vw;
   margin-top: 3vh;
    margin-bottom: 1vh;
  width: fit-content;
   margin-right: auto;
   margin-left: auto;
}
.bigTitle{
  font-size: 2.5vw;
  color: #0496ff;
  font-weight: bold;
  font-family: "Montserrat";
  letter-spacing: -0.1vw;
  margin-bottom: 0;
  margin-top: 5vh;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
}

input[type="text"] {

  border-color: lightgrey;
 
  background-color: transparent;
  color: black;
  font-family: 'Montserrat', sans-serif;
  padding: 5px;
  background-color: whitesmoke;

  transition: width 0.4s ease-in-out;
}
input[type="text"]:focus {
  width: 40%;
  padding: 5px;

}

::placeholder {
  color: black;
  opacity: 0.5;
}
select {
  background-color: transparent;
  margin-top: 10px;
  border: 1px solid;
  border-color: grey;
  border-radius: 5px;
  width: 60%;
  height: 30px;
  border-bottom: 1px solid;
  background-color: whitesmoke;
  color: black;
}
input[type="email"] {
  background-color: transparent;
  border: none;
  border: 1px solid;
  border-color: grey;
  border-radius: 5px;
  width: 30%;
  height: 30px;
  color: black;
  transition: width 0.4s ease-in-out;
  background-color: whitesmoke;
}
input[type="email"]:focus {
  width: 40%;
}
input[type="password"] {
  background-color: transparent;
  border: 1px solid;
  border-color: grey;
  border-radius: 5px;
  background-color: whitesmoke;
  width: 30%;
  height: 30px;
  color: black;
  transition: width 0.4s ease-in-out;
}
input[type="password"]:focus {
  width: 40%;
}
input[type="number"] {
  border: none;
  border: 1px solid;
  border-color: lightgrey;
  border-radius: 5px;
  width: 30%;
  height: 30px;
  background-color: transparent;
  color: black;
  font-family: 'Montserrat', sans-serif;
  padding: 5px;
  background-color: whitesmoke;

  transition: width 0.4s ease-in-out;
}

label {
  font-weight: 400;
  font-size: 20px;
  color: black;
}
button {
  background-color: lightskyblue;
  color: white;
  height: 40px;
  width: 100px;
  border-radius: 7px;
}
.linkr{
  color:lightskyblue;
}
.linkr:link {
  color: lightskyblue;
}
.linkr:visited {
  color: lightskyblue;
}
body {
  background-color: white;
}
.button-doc{
  background-color: #4381C1 !important;
  color: white;
  margin-left: 10px;
  border: 1px solid white !important;
  padding: 5px;
  width: fit-content;
  height: fit-content;
  
}
.button-doc:hover{
  border-bottom: 1px solid #4381C1;
}

.ver-email{
  color: #4381C1;
  margin-left: 5px;
  border-bottom: none;
  font-family: 'Montserrat';
}
.ver-email:hover{

}
.ver-email:visited{
  border:none;
}
