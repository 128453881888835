.clienteInfoPopup {
    z-index: 99;
    position:absolute;
    background-color: white;
    border-radius: 5px;
  }

  .overlay{
    position:fixed;
    z-index:999;
    width:100%;
    height:100%;
    background-color: black;
    background-color:rgba(0,0,0,.75)
}

.cartelleSediContainer{
  display:inline-flex;
  flex-direction: column;
  height: 30vh;
  width:40vw;
  margin-top: 2%;
  overflow-y:auto;
}

.cartellaSede{
  background-color: #4381C1;
  display:flex;
  padding: 1%;
  align-items: center;
  align-content: center;
  margin-bottom: 5px;
}

.cartellaSede:hover{
  background-color: #34699e;
  padding:1.5%;
  border:1px solid black;
}

.cancelIconButton{
  background-color: transparent;
   border: none;
   color:rgb(255, 118, 118);
   width: auto;
    height: auto;
    margin:5px;
}

.buttonIcon{
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  color: #4381C1;
}

.icon:hover{
  width: 43px;
  height: 43px;
}


.cancelIcon:hover{
  background-color: rgb(125, 33, 33) !important;
}

.menuNewFolder{
  display:inline-flex;
  flex-direction: row;
  padding:auto 0;
}

.inputfile::file-selector-button {
  display: none;
}
.title-txt{
  font-size:1vw;
  color:#5C6672;
}
.title-cl{
  margin-top: auto;
  margin-bottom:auto;
  border-bottom:2px solid black;
  font-size: 1.3vw;
  cursor:pointer;
  transition: all 0.1s ease-in;
  font-weight: bold;
}
.title-cl:hover{
  font-size: 1.4vw
}
.subtitle-cl{
  margin-top: auto;
  margin-bottom:auto;
  border-bottom:1px solid black;
  font-size: 1vw;
  cursor:pointer;
  transition: all 0.1s ease-in;
  font-weight: normal;
}
.subtitle-cl:hover{
  font-size: 1.1vw
}
.input-cl{
  background-color:white;
  border-radius:0;
  font-family: 'Rubik';
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  max-width: 15vw;
  min-width: 9vw;
  width:9vw;
  transition: 0.1s linear;
  height: 4vh;

}
.input-cl:hover{
  box-shadow: skyblue 0px 3px 8px;
}
.btn-sub-cl{
  background-color:white;
  box-shadow: RGBA(0,0,0,0.3) 0px 3px 2px;
  color: #0496ff;
  display:inline-flex;
  justify-content: center;
  align-items: center;
  border:none;
  margin-left:0.5vw;
  margin-top:auto;
  font-family: 'Rubik';
  width:9vw;
  font-weight: bold;
  font-size: 0.7vw;
  cursor: pointer;
  border-radius: 10;

}
.btn-sub-cl2{
  background-color:white;
  border-radius:0;
  box-shadow: rgba(255, 0, 0, 0.366) 0px 3px 8px;;
  color: #ef476f;
  display:inline-flex;
  justify-content: center;
  align-items: center;
  border:none;
  margin-left:0.5vw;
  margin-top:auto;
  font-family: 'Rubik';
  width:9vw;
  font-weight: bold;
  font-size: 0.7vw;
  cursor: pointer;


}
.hover-text:hover{
  color:#1D70A2 !important;
}

.hover-bg:hover{
  box-shadow: #0496ff6b 0px 5px 10px !important;
}

.storico{
  transition: all 0.3s ease-in;
}