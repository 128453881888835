:root{
  --color-primary: #6C9BCF;
  --color-danger: #FF0060;
  --color-success: #1B9C85;
  --color-warning: #F7D060;
  --color-white: #fff;
  --color-info-dark: #7d8da1;
  --color-dark: #363949;
  --color-light: rgba(132, 139, 200, 0.18);
  --color-dark-variant: #677483;
  --color-background: #f6f6f9;
  --color-dark-blue: #14213d;
  --card-border-radius: 2rem;
  --border-radius-1: 0.4rem;
  --border-radius-2: 1.2rem;
  --light-blue:#0496ff;
  --card-padding: 1.8rem;
  --padding-1: 1.2rem;
  --blue-botton:#192bc2;

  --box-shadow: 0 2rem 3rem var(--color-light);
}


.background{
  background-color: var(--color-background);
  width:100vw;
  height:100vw;
}

.container-dash{
  display:flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.container-dash-c{
  display:flex;
  flex-direction: column;
  justify-content: space-between;
}

.item1{
  width:22.5rem;
  height:9rem;
  border-radius: 15px;
  box-shadow: var(--box-shadow);
  background-color: white;
  display: flex;
}
.item2{
  width:100%;
  height:15rem ;
  border-radius: 15px;
  box-shadow: var(--box-shadow);


}
@media screen and (max-width: 1440px){
  .item1{
    width:17.7rem;
    height:9rem;
    border-radius: 15px;
    box-shadow: var(--box-shadow);
    background-color: white;
    display: flex;
  }
  .calendar-box-inner{
    width:60rem;
    height:1rem;
    border-radius:10;
    display:flex;
    align-items:center;
  }
  .item2{
    width:80%;
    height:10rem ;
    border-radius: 15px;
    box-shadow: var(--box-shadow);
  
  
  }
  
}