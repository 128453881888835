.loginInput{
  margin-top: 0.5vh;
  font-weight: bold;
  width: 30vw !important;
  font-family: "Montserrat";
  border-radius: 5 !important;
  background-color: white;
  border: 1px solid #5C6672 !important ;
  font-size: 0.8vw;
  color: black;
  padding: 5 !important;
}

input:-webkit-autofill:focus {
  background-color: white !important; /* Colore anche quando è in focus */
}

input:-moz-autofill {
  background-color: white !important; /* Per Firefox (meno comune) */
}
.background {
  height: 100vh;
  width: 100%;
  position: static;
  font-family:'Montserrat', sans-serif;
  font-weight: 200 !important;
}
images {
  opacity: 0.5;
}
.space {
  height: 20%;
}
.left {
  width: 35vw;
  height: 100vh !important;
  background: rgb(255, 255, 255);
}
.right {
  width: 65%;
  height: 100%;
  position: static;
  background-position: center;
  background-size: cover;
}
.textLogin {
  padding-top: 5px;
  font-size: 12px;
}
.text {
  font-family: 'Open Sans', spectral, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: bold;
  color: white;
}
.title {
  font-size: 30px;
  font-family:  'Open Sans', sans-serif;
}
.control {
  width: 300px;
  height: 30px;
  border-color: lightgrey;
  resize: none;
}
.btnL {
  border-radius: 10px;
  width: 150px;
  height: 40px;
  background-color: red;
  color: white;
  font-family: 'Roboto',Milliard, Roboto, BlinkMacSystemFont, "Segoe UI", -apple-system,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    
}
.btnR {
  width: fit-content;
  height: 70px;
  text-decoration:none;
  color: #ef476f;
  font-size: 14px;
  font-family:'Montserrat','Montserrat',Milliard, Roboto, BlinkMacSystemFont, "Segoe UI", -apple-system,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.btnR:hover{
text-decoration: underline;
}
.loginlink:link {
  color: red;
}
.loginlink:hover {
  color: rgba(178, 0, 0, 0.601);
}
.loginlink:visited {
  color: red;
}
.loginlink:active {
  color: lightgrey;
}
.loginForm {
  border: 1px solid lightgrey;
  width: 400px;
  height: fit-content;
  border-radius: 10px;
  text-align: center;
  position: absolute;
  top: 15vh;
  left: 33vw;
  background-color: whitesmoke;

}
@media all and (min-width: 480px) {
  .Login {
    padding: 10px 0;
    font-family: 'Roboto', BlinkMacSystemFont, "Segoe UI", -apple-system, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  }
}
.textright {
  margin-top: 190px;
  margin-left: 40px;
  margin-right: 40px;
  

  font-family: 'Roboto',Roboto, BlinkMacSystemFont, "Segoe UI", -apple-system, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.loginlinkr:link {
  font-size: 30px;
}
.titleR {
  font-size: 5vw;
  color: white;
  font-weight: 700;
}
.subR {
  color: white;
}
.textR {
  color: white;
  margin-top: -20px;
  font-weight: 400;
  font-size: 1.5vw;
}
.registerDiv {
  background-color: rgba(211, 211, 211, 1);
  border-radius: 5px;
  font-family: 'Roboto',"Roboto Nero", BlinkMacSystemFont, "Segoe UI", -apple-system,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

