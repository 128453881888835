:root{
  --color-primary: #6C9BCF;
  --color-danger: #FF0060;
  --color-success: #1B9C85;
  --color-warning: #F7D060;
  --color-white: #fff;
  --color-info-dark: #7d8da1;
  --color-dark: #363949;
  --color-light: rgba(132, 139, 200, 0.18);
  --color-dark-variant: #677483;
  --color-background: #f6f6f9;
  --color-dark-blue: #14213d;
  --card-border-radius: 2rem;
  --border-radius-1: 0.4rem;
  --border-radius-2: 1.2rem;
  --light-blue:#0496ff;
  --card-padding: 1.8rem;
  --padding-1: 1.2rem;
  --blue-botton:#192bc2;

  --box-shadow: 0 2rem 3rem var(--color-light);
}

.NavBarItems {
  background: white;
  display:flex;
  flex-direction: column;
  align-items: left;
  font-size: 1.2rem;
  width: 13rem;
  transition: .4s ease;
  box-shadow: var(--box-shadow) !important;
  border-radius: 15px;
  height: 88vh;
  position: relative;
  top: 0rem;
  transition: all 0.3s ease;
  margin-left: 2rem;
}
.NavBarItems2 {
  background: white;
  display:flex;
  flex-direction: column;
  align-items: left;
  font-size: 1.2rem;
  width: 13rem;
  transition: .4s ease;
  box-shadow: var(--box-shadow) !important;
  border-radius: 15px;
  height: 98vh;
  position: relative;
  top: 1vh;
  transition: all 0.3s ease;
  margin-left: 2rem;
  z-index: 100000;
}

.NavBarItems:hover{
  box-shadow: none !important;
}

.NavBarItems2:hover{
  box-shadow: none !important;
}
.nav-bar-icon{
  color:var(--color-info-dark);
  font-size:1.5rem !important;
}
.nav-bar-text{
  color:var(--color-info-dark);
  font-size: 0.9rem !important;
  font-family:'Poppins'

}



.box-nav-bar{
  display: flex;
  align-items: center;
  height:3.7rem ;
  gap:0.5rem;
  margin-left:2rem;

}

.box-nav-bar:hover .nav-bar-icon{
  color:var(--blue-botton)
}

.nav-logo {
  justify-self: start;
  cursor: pointer;
}



.nav-links:link {
  color: var(--blue-botton);
}
.nav-links:visited {
  color: var(--blue-botton);
}
.nav-links:hover {
  color: var(--blue-botton);
}
.nav-links:hover p{
  color: var(--blue-botton) !important;
}
.nav-links2 p{
  width: 100%;
  color: var(--blue-botton);
}
.nav-links2  .nav-bar-icon{
  color:var(--blue-botton);
  font-size:1.5rem !important;
}
.nav-links2  .box-nav-bar{
  color:var(--color-primary);
  font-size:1.5rem !important;
  background-color: var(--color-light);
  margin-left: 0;
  border-left: 5px solid var(--blue-botton);
  padding-left:1rem;
  
}

@media screen and (max-width: 1440px){
  .NavBarItems {
    background: white;
    display:flex;
    flex-direction: column;
    align-items: left;
    font-size: 1rem;
    width: 11rem;
    transition: .4s ease;
    box-shadow: var(--box-shadow) !important;
    border-radius: 15px;
    height: 88vh;
    position: relative;
    top: 0rem;
    transition: all 0.3s ease;
    margin-left: 1rem;
  }
  .nav-links2  .nav-bar-icon{
    color:var(--blue-botton);
    font-size:1.2rem !important;
  }
  .box-nav-bar{
    display: flex;
    align-items: center;
    height:3rem ;
    gap:0.5rem;
    margin-left:1rem;
  
  }
  .nav-bar-icon{
    color:var(--color-info-dark);
    font-size:1.2rem !important;
  }
  .nav-bar-text{
    color:var(--color-info-dark);
    font-size: 0.7rem !important;
    font-family:'Poppins'
  
  }
}



